import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableHeader } from '@material-table/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOrdersPerPage } from 'api/corememData';
import { Event } from 'SocketIO';
import { TableCell } from '@material-ui/core';
import { dateToDateTimeString } from 'assets/js/common';
import { makeStyles } from '@material-ui/core/styles';
import { getValueColor } from 'assets/js/common';
import _ from 'lodash';

const useStyle = makeStyles(theme => {
  return {
    buyColor: {
      color: theme.palette.orders.buy,
    },
    sellColor: {
      color: theme.palette.orders.sell,
    },
  };
});

export default function MaterialTableDemo(props) {
  const { exchange, pair, heads = [], bigger } = props;
  const tableRef = useRef();
  const classes = useStyle();
  const [rowCount, setRowCount] = useState(
    _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
      ? 5
      : parseInt(localStorage.getItem('recentTradesOverViewSidebar'))
  );

  const refreshData = () => {
    if (tableRef?.current) {
      tableRef.current.onQueryChange();
    }
  };

  // Create a debounced version of refreshData delaying execution by 10 seconds.
  const debouncedRefreshData = useRef(
    _.debounce(() => {
      refreshData();
    }, 10000)
  );

  // Immediate execution function: cancel any pending debounced call and refresh immediately.
  const handleImmediateRefresh = () => {
    debouncedRefreshData.current.cancel();
    refreshData();
  };

  const updateRowCount = tableRefRef => {
    if (tableRefRef?.current) {
      const savedRowCount = _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
        ? 5
        : parseInt(localStorage.getItem('recentTradesOverViewSidebar'));
      setRowCount(savedRowCount);
      if (tableRefRef.current.dataManager.pageSize !== savedRowCount) {
        localStorage.setItem(
          'recentTradesOverViewSidebar',
          tableRefRef.current.dataManager.pageSize.toString()
        );
      }
    }
  };

  useEffect(() => {
    // Instead of calling refreshData immediately, call the debounced version.
    debouncedRefreshData.current();
    updateRowCount(tableRef);
  }, [exchange, pair]);

  useEffect(() => {
    let timer1 = setInterval(() => {
      updateRowCount(tableRef);
    }, 30000);

    let timer2 = setInterval(() => {
      refreshData();
    }, 30000);

    return () => {
      if (timer1) clearInterval(timer1);
      if (timer2) clearInterval(timer2);
    };
  }, []);

  // Cleanup: Cancel any pending debounced call when the component unmounts.
  useEffect(() => {
    return () => {
      debouncedRefreshData.current.cancel();
    };
  }, []);

  // This function is where we load data, do local searching, and do local sorting
  const loadData = query => {
    // Keep row size in sync with localStorage
    const savedRowCount = _.isNil(localStorage.getItem('recentTradesOverViewSidebar'))
      ? 5
      : parseInt(localStorage.getItem('recentTradesOverViewSidebar'));
    const newRowCount = query.pageSize > savedRowCount ? query.pageSize : savedRowCount;
    query.pageSize = newRowCount; // update the query so the table picks it up

    if (!exchange) {
      return Promise.resolve({
        page: 0,
        data: [],
        totalCount: 0,
      });
    }

    // Fetch data from the API
    return getOrdersPerPage(`${exchange}/${pair}`, query).then(result => {
      // result should be of shape { data: [...], page: X, totalCount: Y } from your API,
      // but we can do local filtering & sorting if needed.

      // If you need local searching:
      let localData = result.data;
      if (query.search) {
        localData = localData.filter(item =>
          JSON.stringify(item).toLowerCase().includes(query.search.toLowerCase())
        );
      }

      // If you need local sorting (like the first snippet):
      if (query.orderBy && query.orderDirection) {
        localData = _.orderBy(localData, [query.orderBy.field], [query.orderDirection]);
      }

      // Return a structure that MaterialTable can understand
      return {
        data: localData,
        page: query.page,
        totalCount: localData.length, // or the original total if you prefer
      };
    });
  };

  // Define columns
  let columnDefinitions = [
    { title: 'Rate', field: 'rate', defaultSort: 'desc' },
    { title: 'Amount', field: 'amount' },
    { title: 'Time', field: 'time', type: 'datetime' },
  ];

  // If custom heads are passed in, build columns from them
  if (heads.length > 0) {
    columnDefinitions = [];
    heads.forEach(element => {
      if (element.title === 'Rate') {
        columnDefinitions.push({ title: 'Rate', field: 'rate' });
      } else if (element.title === 'Pnl') {
        columnDefinitions.push({ title: 'Pnl', field: 'pnl' });
      } else if (element.title === 'Amount') {
        columnDefinitions.push({ title: 'Amount', field: 'amount' });
      } else if (element.title === 'Amount (base)') {
        columnDefinitions.push({ title: 'Amount (base)', field: 'amountBase' });
      } else if (element.title === 'Time') {
        columnDefinitions.push({ title: 'Time', field: 'time', type: 'datetime' });
      }
    });
  }

  return (
    <Event event="connect" handler={handleImmediateRefresh}>
      <MaterialTable
        style={
          bigger
            ? { marginLeft: '-8px', minWidth: '100%', maxWidth: '100%', minHeight: '100%' }
            : { marginLeft: '-8px', minWidth: '100%', maxWidth: '100%' }
        }
        title="Recent trades"
        columns={columnDefinitions}
        options={{
          toolbar: bigger,
          pageSize: rowCount,
          headerStyle: {
            padding: '6px 10px 6px 10px',
          },
          rowStyle: {
            padding: '6px 10px 6px 10px',
          },
        }}
        data={loadData}
        editable={null}
        tableRef={tableRef}
        components={{
          OverlayLoading: () => {
            return (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color={'secondary'} />
              </div>
            );
          },
          Header: function (props) {
            return <MTableHeader {...props} size={bigger ? 'medium' : 'small'} />;
          },
          Cell: function ({ columnDef, value, rowData }) {
            let cell;
            switch (columnDef.field) {
              case 'rate':
                cell = (
                  <span className={rowData.type === 'buy' ? classes.buyColor : classes.sellColor}>
                    {value}
                  </span>
                );
                break;
              case 'pnl':
                cell = <span style={{ color: getValueColor(value) }}>{value}</span>;
                break;
              case 'amountBase':
                cell = <span>{(rowData.rate * rowData.amount).toFixed(4)}</span>;
                break;
              case 'time':
                cell = <span>{dateToDateTimeString(value)}</span>;
                break;
              default:
                cell = (
                  <span>
                    {localStorage.getItem('privacyMode') == 'true' ? '●●●●●' : value}
                  </span>
                );
                break;
            }
            return (
              <TableCell size={bigger ? 'medium' : 'small'} style={{ whiteSpace: 'nowrap' }}>
                {cell}
              </TableCell>
            );
          },
        }}
      />
    </Event>
  );
}

MaterialTableDemo.propTypes = {
  exchange: PropTypes.string,
  pair: PropTypes.string,
};
